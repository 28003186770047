@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

* {
  box-sizing: border-box;
}
html {
  height: 100vh;
  width: 100vw;
}
html, body {
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', Arial, Verdana, 'Times New Roman', Times, serif;
  margin: 0;
}
.Home {
  position: relative;
  text-align: center;
  height: 100vh;
  overflow-y: scroll;
}
#inputs{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  /* top: 10px; */
  /* border: 1px solid red; */
  height: 85%;
  width: 100%;
}
#project_div b{
  width: 100%;
  display: block;
}
.form_cont{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form {
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 90%;
  border: 1px solid black;
  margin-bottom: 2px;
  text-align: left;
  padding: 5px;
}
.payment{
  width: 90%;
  border: 1px solid black;
  margin-bottom: 2px;
  text-align: left;
  padding: 5px;
}
.payment div {
  margin-bottom: 5px;
}
.payment input {
  margin-right: 30px;
  width: 18vw;
}
label{
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  /* width: 250px; */
  /* border: solid red; */
}
/* #total_requested_in_words{
  width: 300px;
} */
.application-div select{
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
}
.application-div label, .application-div select, .application-div input, .payment label {
  width: 200px;
}
#project_div label {
  width: 300px;
}
#cc::before {
  content: 'OFFICE USE ONLY';
  width: 100%;
  font-size: 12px;
  position: absolute;
  top: -21px;
  left: 0px;
  text-align: center;
  font-weight: lighter;
  background-color: rgb(202, 214, 216);
}
#tables-cont {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid black;
  height: 55%;
  width: 90%;
}
#table-form {
  height: 80%;
  overflow-y: scroll;
}
table {
  width: 100%;
  border-collapse: collapse;
}
#the-table {
  position: relative;
  height: 100%;
  border: 1px solid black;
}
thead {
  /* border: 1px solid black; */
}
tbody {
  /* border: 1px solid black; */
  /* height: 60%!important; */
  overflow-y: scroll;
}
tr {
  /* border: 1px solid black; */
}
td, th {
  text-align: center;
}
table input[type="number"]{
  width: 100%;
  border: none;
}
input[type="text"], input[type="date"] {
  border: none;
  border-bottom: 1px solid black;
}
td{
  border: 1px solid black;;
}

.item_description, table input[type="text"]{
  width: 100%;
  border: none;
}
.number-td{
  width: 120px;
}
.tot-td{
  width: 140px;
}
.cc-td{
  width: 200px;
}
tfoot td{
  text-align: right;
  font-weight: bold;
  border: none;
}
#total{
  /* text-align: center; */
  display: inline-block;
}
tr{
  position: relative;
}
th{
  position: relative;
}

button {
  margin-top: 10px;
  width: 200px;
  background-color: rgb(103, 155, 24);
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.Home-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Home-logo {
    animation: Home-logo-spin infinite 20s linear;
  }
}

.Home-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Home-link {
  color: #61dafb;
}

@keyframes Home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
