#login-cont {
    width: 100%;
    height: 100vh;
    border: 1px solid green;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#login-form {
    display: relative;
    border: 3px solid greenyellow;
    width: 400px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    border-radius: 10px;
}

#login-form h3 {
    display: absolute;

}

#login-form label{
    width: 100px;
    text-align: left;
}

#login-form input{
    border: none;
    border-bottom: 1px solid grey;
}
#login-form input[type="text"]{
    border-bottom: 1px solid grey;
}

button{
    cursor: pointer;
}