.loading-screen {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.8);
}

@keyframes spinner {
    to { transform: rotate(360deg); }
}

.spinner {
    animation: spinner 1s linear infinite;
    border: solid 2px rgba(220, 251, 16, 0.421);
    border-top-color: #85b73a;
    border-left-color: #b2f732;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

