.mod {
    position: relative;
    /* border: 1px solid greenyellow; */
    width: 100%;
    height: 100%;
    /* position: absolute; */
    /* left: calc(50% - 250px); */
    /* top: calc(50% - 250px); */
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.9);
    color: white;
}
.mod-header{
    position: relative;
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 60px;
}
.mod-body{
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    /* border: 1px solid greenyellow; */
}

.mod-close-btn {
    background-color: rgb(206, 66, 66);
    margin: 5px;
}
.mod-submit-btn {
    background-color: rgb(66, 184, 72);
    margin: 5px;
}